<template>
  <Layout>
    <Header />
    <div class="container-fluid p-0">
      <div class="banner position-relative">
        <img src="@/assets/images/app/article.jpg" :alt="title" class="img-fluid">
        <div class="position-absolute top-50 start-0 translate-middle-y w-100">
          <div class="container text-white px-3">
              <div class="row justify-content-end">
              <div class="col-lg-8 col-10 text-end">
                <h1 class="text-white display-4 animate__animated animate__slideInRight font-style-2 mt-4 mt-md-0">{{$t('articles')}}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-3 my-lg-5">
      <div class="row">
        <div class="col-12 col-lg-6 mb-4 px-4 px-md-3" v-for="(value, index) in articles" :key="index">
          <router-link :to="`/article/${convertToSlug(value.date)}/${index}/${convertToSlug(value.title)}`">
          <b-card no-body class="rounded position-relative h-100 pb-0">
              
              <div class="position-relative">
                <b-card-img
                :src="value.image"
                class="rounded"
              ></b-card-img>
              <div class="position-absolute top-0 start-0 w-100 h-100 bg-primary opactiy-2 rounded-top"></div>
              </div>
              
              <b-card-body class="p-2">
                <b-card-title>
                  <h5 class="card-title text-primary fw-medium px-3 text-capitalize mt-3">{{value.title}}</h5>
                </b-card-title>
                <b-card-text class="px-3 text-muted">
                  {{value.smallDescription}}
                </b-card-text>
                <p class="card-text px-3 mb-0 mt-auto">
                  <small class="text-muted ">{{$t('published_on')}}  {{value.date}}</small>
                </p>
              </b-card-body>
            
        </b-card>
        </router-link>
        </div>
      </div>
    </div>
    
    <Enquiry />
    <Contact />
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import Header from "@/views/pages/drlim/components/header";
import Enquiry from '@/views/pages/drlim/components/enquiry';
import Contact from '@/views/pages/drlim/components/contact';
/**
 * Starter page
 */
export default {
  components: { Layout , Header, Enquiry, Contact},
  page: {
    title: "Articles",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  
  data() {
    return {
      title: appConfig.title,
      items: [],
      articles: [
        {
          title: "活力加油站 Living Delight (2020) | 2020年10月21日: 肛周脓肿，或因免疫力低下？",
          smallDescription: " 今日主题：● 肛周脓肿，或因免疫力低下？ ● 严重可引发肛瘘？ ● 如何远离肛周脓肿？● 谁是大主厨？ #活力加油站 #LivingDelight",
          image: require('@/assets/images/app/article/20201021.jpg'),
          date:"2020-01-21"
        },
        {
          title: "Early detection and diagnosis for prompt, cost-effective cancer interventions",
          smallDescription: "Indonesia marked this year’s World Cancer Day with a bold call from Health Minister Budi Gunadi Sadikin and others for widespread early screening for the disease so that it can be treated as effectively as possible.",
          image: require('@/assets/images/app/article/20230325.jpg'),
          date:"2023-03-25"
        },
        {
          title: "Colorectal cancer on the rise within the younger age group",
          smallDescription: "Dietary habits such as excessive processed food, consuming less fibre, and penchant for eating out are part of the problem.",
          image: require('@/assets/images/app/article/20230330.jpg'),
          date:"2023-03-20"
        },
        {
          title: "Take rectal bleeding seriously",
          smallDescription: "Lack of awareness on colorectal procedures and newer cancer treatments leave Malaysians in the dark.MANY Malaysians find themselves at the end of an earth-shattering call from their doctor with a late-stage diagnosis of colorectal cancer – one of the most prevalent cancer in Malaysia.",
          image: require('@/assets/images/app/article/20230412.jpg'),
          date:"2023-04-12"
        },
        {
          title: "MELODY健康星期四 - 生痔疮, 该怎么办?",
          smallDescription: "一旦患上痔疮，那将会是一种无法言喻的痛。那这种痛应该要如何才能治愈呢？生活上的哪些习惯会让你和痔疮的距离变得更近? 【早晨有意思】之【健康星期四】，有Prince Court Medical Centre普通及大肠外科顾问，林享懃医生和你讲解更多。#MELODY早晨有意思 #MELODY健康星期四",
          image: require('@/assets/images/app/article/20230420.jpg'),
          date:"2023-04-20"
        },

      ]
     
    };
  },
  created(){
    this.setArticleList()
  },
  mounted(){
  },
  methods: {
    setArticleList() {
      var data = this.articles.map((article, index) => ({
        id: index,
        name: this.convertToSlug(article.title),
        date:article.date
      }));
      console.log(data)
    },
    convertToSlug(str) {
      // Replace all spaces with dashes
      str = str.replace(/[\s_,.|:()]+/g, '-');
      // Convert all characters to lowercase
      str = str.toLowerCase();
      return str;
    }
  },
}
</script>
<style coped>
 a .opactiy-2{
  opacity:0.6;
  transition: 0.3s;
 }
 a:hover .opactiy-2{
  opacity:0.2;
 }
</style>